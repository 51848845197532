import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FAQIcon,
  GuidesIcon,
  HelpIcon,
  SupportIcon,
  VideoIcon,
} from '@kuna-pay/ui/icons';
import { NavLink } from '@kuna-pay/ui/router';
import { Link } from '@kuna-pay/ui/router';
import { UnstyledButton } from '@kuna-pay/ui/ui/button';
import { urls } from '@kuna-pay/core/shared/config';
import { GenericFeedback } from '@kuna-pay/core/shared/feedback';

import { ContactSupport } from '@kuna-pay/merchant/features/contact-support';
import { $$tutorialsFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/dynamic';
import { routes } from '@kuna-pay/merchant/shared/router';

import { ReactComponent as CompanyFeesIcon } from '../../../assets/company-fees.svg';
import { ReactComponent as FeedbackIcon } from '../../../assets/feedback.svg';
import { $$headerMenu } from '../header-menu.model';
import { HeaderMenuDropdown } from '../ui';
import styles from './help-menu.module.scss';

const DesktopHelpMenu = memo(() => {
  const { t, i18n } = useTranslation();

  const [isLoggedIntoCompany, isTutorialsFeatureFlagEnabled] = useUnit([
    $$headerMenu.$$userMenu.$isCompanyLogged,
    $$tutorialsFeatureFlag.$enabled,
  ]);

  return (
    <HeaderMenuDropdown
      className={styles.contactSupportDropdown}
      content={
        <>
          {isLoggedIntoCompany && (
            <HeaderMenuDropdown.Item.Root asChild>
              <NavLink to={routes.companyFees.root}>
                <HeaderMenuDropdown.Item.Icon>
                  <CompanyFeesIcon />
                </HeaderMenuDropdown.Item.Icon>

                <HeaderMenuDropdown.Item.Text>
                  {t('widgets.layout.header-menu.help-menu.company-fees')}
                </HeaderMenuDropdown.Item.Text>
              </NavLink>
            </HeaderMenuDropdown.Item.Root>
          )}

          <ContactSupport.Trigger $$model={$$headerMenu.$$contactSupport}>
            <HeaderMenuDropdown.Item start={<SupportIcon />}>
              {t('widgets.layout.header-menu.help-menu.support')}
            </HeaderMenuDropdown.Item>
          </ContactSupport.Trigger>

          {isTutorialsFeatureFlagEnabled && (
            <HeaderMenuDropdown.Item.Root asChild>
              <NavLink to={routes.tutorials.list}>
                <HeaderMenuDropdown.Item.Icon>
                  <VideoIcon />
                </HeaderMenuDropdown.Item.Icon>

                <HeaderMenuDropdown.Item.Text>
                  {t('widgets.layout.header-menu.help-menu.tutorials')}
                </HeaderMenuDropdown.Item.Text>
              </NavLink>
            </HeaderMenuDropdown.Item.Root>
          )}

          <HeaderMenuDropdown.Item.Root asChild>
            <Link to={urls.docs.root.withLocale(i18n.language)} target='_blank'>
              <HeaderMenuDropdown.Item.Icon>
                <GuidesIcon />
              </HeaderMenuDropdown.Item.Icon>

              <HeaderMenuDropdown.Item.Text>
                {t('widgets.layout.header-menu.help-menu.guides')}
              </HeaderMenuDropdown.Item.Text>
            </Link>
          </HeaderMenuDropdown.Item.Root>

          <HeaderMenuDropdown.Item.Root asChild>
            <Link
              to={urls.docs.onboarding.withLocale(i18n.language)}
              target='_blank'
            >
              <HeaderMenuDropdown.Item.Icon>
                <FAQIcon />
              </HeaderMenuDropdown.Item.Icon>

              <HeaderMenuDropdown.Item.Text>
                {t('widgets.layout.header-menu.help-menu.faq')}
              </HeaderMenuDropdown.Item.Text>
            </Link>
          </HeaderMenuDropdown.Item.Root>

          <GenericFeedback.Trigger $$model={$$headerMenu.$$feedback}>
            <HeaderMenuDropdown.Item start={<FeedbackIcon />}>
              {t('widgets.layout.header-menu.help-menu.feedback')}
            </HeaderMenuDropdown.Item>
          </GenericFeedback.Trigger>
        </>
      }
    >
      <UnstyledButton className={styles.help}>
        <HelpIcon />
      </UnstyledButton>
    </HeaderMenuDropdown>
  );
});

export { DesktopHelpMenu };
