import { createDynamicFlag } from '../model/dynamic.factory';

const $$testDepositFeatureFlag = createDynamicFlag(
  'KUPAY_1738_MERCHANT_TEST_DEPOSIT'
);

const $$invoiceConvertFeatureFlag = createDynamicFlag(
  'KUPAY_2752_FE_MERCHANT_INVOICE_CONVERT'
);

const $$customInvoiceFieldFeatureFlag = createDynamicFlag(
  'KUPAY_2700_STORY_CUSTOM_FIELD_TO_QUESTIONNAIRE'
);

const $$tutorialsFeatureFlag = createDynamicFlag(
  'KUPAY_2620_FE_TUTORIALS',
);

export {
  $$customInvoiceFieldFeatureFlag,
  $$invoiceConvertFeatureFlag,
  $$testDepositFeatureFlag,
  $$tutorialsFeatureFlag,
};
