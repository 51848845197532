import clsx from 'clsx';
import { memo, useState, type VideoHTMLAttributes } from 'react';

type TutorialVideoProps = VideoHTMLAttributes<HTMLVideoElement>;

const TutorialVideo = memo(({ className, ...props }: TutorialVideoProps) => {
  const [hovering, setHover] = useState(false);
  const [focused, setFocused] = useState(false);

  return (
    <video
      className={clsx(className)}
      controls={hovering || focused}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      {...props}
    />
  );
});

export type { TutorialVideoProps };
export { TutorialVideo };
